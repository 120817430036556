body {
  margin: 0;
  font-family:
    "Source Sans 3",
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.tooltipVariant1 {
  color: #222;
  background-color: rgb(255, 153, 0);
}

.tooltip {
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 90%;
  width: max-content;
  background-color: rgb(255, 153, 0);
}

.stats {
  left: auto !important;
  top: auto !important;
  right: 0px;
  bottom: 0px;
}
